.ourteam{
    color: #ffffff;
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Inknut Antiqua;
font-size: 45px;
font-weight: 700;
line-height: 24px;
text-align: left;
padding-top: 10px;
}
.profile-card-container {
    display: flex;
    flex-wrap:wrap;
   
    justify-content: center;
    padding: 20px;
    background-color: #121212; /* Dark background */
  }
  
  .profile-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 295px;
    margin: 50px;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .profile-image {
    border-radius: 50%;
    width: 85px;
    height: 85px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
  }
  
  .profile-details h1 {
    font-size: 24px;
    margin: 0;
    font-family: Inknut Antiqua;
font-size: 32px;
font-weight: 700;
line-height: 24px;
text-align: left;

  }
  
  .profile-details h2 {
    font-size: 16px;
    margin: 0;
    font-family: Inknut Antiqua;
font-size: 14px;
font-weight: 700;
line-height: 24px;
text-align: left;

    color: #808080
  }
  
  .profile-details p {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  .social-icons {
    display: flex;
    margin-top: 10px;
  }
  
  .social-icons a {
    margin-right: 10px;
  }
  
  .social-icons img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 768px) {
    .ourteam {
      font-size: 24px;
      padding: 15px 5px;
    }
  
    .profile-card {
      width: 200px;
      margin: 30px;
      padding: 15px;
    }
  
    .profile-image {
      width: 80px;
      height: 80px;
    }
  
    .profile-details h1 {
      font-size: 24px;
    }
  
    .profile-details h2 {
      font-size: 12px;
    }
  
    .profile-details p {
      font-size: 12px;
    }
  
    .social-icons img {
      width: 20px;
      height: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .ourteam {
      font-size: 20px;
      padding: 10px 5px;
    }
  
    .profile-card {
      width: 250px;
      margin: 20px;
      padding: 10px;
    }
  
    .profile-image {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  
    .profile-details h1 {
      font-size: 20px;
    }
  
    .profile-details h2 {
      font-size: 10px;
    }
  
    .profile-details p {
      font-size: 10px;
      margin-top: 5px;
    }
  
    .social-icons img {
      width: 16px;
      height: 16px;
    }
  }
  
  