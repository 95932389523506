.Main-policy{
        background-color: black;
        color: white;
       height: fit-content; /* Ensures full viewport height */
        overflow: hidden;
        padding: 20px; /* Add padding for better spacing */
       
            
      
}
.Policy-points{
    border:1px solid white;
    width:80%;
    height:90%;
    margin-top: 150px;
    color:BLACK;
    margin-left: 8%;
    border-radius: 20px;
    padding: 20px;
    background-color: #F5F5DC;

}
.Policy-points h4{
    margin-left: 15px;
}
.Policy-points p{
   margin-left: 15px;
    color:grey;
    line-height: 100%;
}