
.contact-form {
    height: 50vh;
    width: 100%;
    padding: 15px;
    background-color: #000000;
    margin-top: 110px;
    font-family: 'inknut antiqua','sans-sarif';
    
    color: white;
    float: inline-start;
    
    
    
  }
 

  .contact-links{
    width:max-content;
    height: fit-content;
    background-color: #222;
    padding: 15px;
    border-radius: 5px ;
    font-family: 'inknut antiqua','sans-sarif';
    font-weight: 100;
    font-size: large;

  }
  .contact-links a{
    color: #1c71bb;
    text-decoration: none;
  }
  @media (max-width: 480px) {
    .contact-form {
        height: auto;
        padding: 10px;
        padding-bottom:25vh;
        font-size: 14px;
    }
    .contact-links {
        width: 100%;
        padding: 10px;
        font-size: medium;
    }
}

/* For screens that are between 481px and 768px wide */
@media (min-width: 481px) and (max-width: 768px) {
    .contact-form {
        height: auto;
        width: 100%;
        padding: 12px;
        font-size: 16px;
    }
    .contact-links {
        width: 100%;
        padding: 12px;
        font-size: large;
    }
}