.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 50%;
  display: flex;
  align-items: center;
  justify-content:center;
}

.player{
  position: absolute;
  top: 250px;
  left: -250px;
}
@media(max-width:768px){
  .player{
    position: static;
    margin-bottom: 200px;
    
  }
}
@media (max-width:480px) {
  .player{
    position: static;
    max-width: 80%;
    margin-bottom: 100px;
    height: 10vh;
    top: -100px;
    left: 20px;

  }
}
