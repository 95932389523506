.ques {
    background-color: #333;
    color: #fff;
    font-family: serif;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    justify-content: space-between;
    height: 50px;
    
    align-items: center;
    cursor: pointer;
    transition: height 0.3s ease-in-out;
}

.main-sec {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.heading-ques {
    margin-top: 30px;
    padding: 10px;
    color: #fff;
    font-family: "Inknut Antiqua", serif;
}

.ques h4 {
    font-size: large;
}

.ques p {
    color: grey;
    width: 1000px; /* Adjusted for responsiveness */
    display: none;
    margin-top: 10px; /* Added margin for spacing */
}
.btn-homeques{
    background-color:  #0081A1;
    color:#ffffff;
    height: 50px;
    width: 300px;
    cursor: pointer;
    font-family:  serif;
    font-weight: bold;
    font-size: large;
    font-style: normal;
    margin-top: 20px;
    transition: 0.2s linear;
}
.ques:hover {
    height: 150px; /* Adjusted height on hover */
    transition: 0.1s ease-in;
    & p{
        display: block;  
    }

}

/* Media Query for Tablets and Smaller Devices */
@media screen and (max-width: 768px) {
    .ques {
        height: 80px; /* Adjusted height for smaller screens */
    }

    .ques p {
        width: 100%; /* Full width for smaller screens */
        display: none; /* Hide by default */
        margin-top: 10px; /* Added margin for spacing */
    }

    .ques:hover {
        height: auto; /* Adjusted height on hover for smaller screens */
    }
}

/* Media Query for Mobile Phones */
@media screen and (max-width: 480px) {
    .ques {
        height: 100px; /* Adjusted height for mobile phones */
    }
}