/* .aboutcontainer{
    background-color: black;
    color: white;
    height: 850px;
    overflow: hidden;
} */
.aboutcontainer {
    background-color: black;
   
    color: white;
    min-height: 100vh; /* Ensures full viewport height */
    overflow: hidden;
    padding: 2rem 1rem; /* Add padding for better spacing */
    
  }
.aboutupper{
display: flex;
background-image: url('../Images/Desktop14.png');
background-size: cover;
background-repeat: no-repeat;
justify-content: center;
align-items: center;
flex-direction: column;
height: 300px;
margin-top: 130px;
}
.aboutupper h1{
 color: #ffffff;
 font-family: Inknut Antiqua;
font-size: 74px;
font-weight: 700;
line-height: 56px;
text-align: left;

}

.aboutupper span{
    font-family: Inknut Antiqua;
    font-size: 16px;
    font-weight: 700;
    line-height: 56px;
    text-align: left;
    color: #808080;    
}
.aboutupper span a{
    color: #808080;
    text-decoration: none;
}
.aboutupper span a:hover {
    color: #ffffff;
  }

.aboutlower{
    display: flex;
    justify-content: center;
    height: 700px;
    align-items: center;
    position: relative;
    margin-top: -150px;
}
.aboutlower-left{
 position: absolute;
left: 174px;
top: 200px;

}
.aboutlower-left img{
   height: 399px;
   width: 399px;
}
.aboutlower-right{
    position: absolute;
 width: 480px;
 left: 700px;
 top: 200px;
}
.aboutlower-right-upper{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}
.aboutblue-line {
  height: 5px;
  width: 154px;
  left: 00px;
  top: -20px;
  background-color: #55B7CE;
}

.aboutheading h2{
    font-family: Inknut Antiqua;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
   
    
    
}

.aboutlower-right-down{
    font-family: Inknut Antiqua;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #808080;    
}

.aboutbutton{
    background-color:  #0081A1;
    color: #ffffff;
    height: 49px;
    width: 179px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 25px;
    transition: color 0.3s ease;
    margin-bottom: 50px;
}
.aboutbutton:hover {

    color:black
   }
   @media (max-width: 768px) {
    .aboutcontainer {
      padding: 1rem; /* Adjusted padding */
    }
    .aboutupper h1 {
        font-size: 3rem; /* Adjusted font size for smaller screens */
        line-height: 1.2; /* Adjusted line height */
        text-align: center; /* Center align text for better readability */
      }
      .aboutupper span {
        font-family: 'Inknut Antiqua', serif;
        font-size: 1rem; /* Adjusted font size for smaller screens */
        font-weight: 700;
        line-height: 1.2; /* Adjusted line height */
        text-align: center; /* Center align text for better readability */
        color: #808080;
      }
      .aboutlower {
        display: flex;
        flex-direction: column; /* Stack items vertically on smaller screens */
        justify-content: center;
        align-items: center;
        height: auto; /* Allow the height to adjust based on content */
        padding: 1rem; /* Add padding for better spacing */
        position: relative;
      }  
      .aboutlower-left {
        position: relative; /* Change to relative positioning for smaller screens */
        left: 0; /* Reset left position */
        top: 0; /* Reset top position */
        margin-bottom: 1.5rem; /* Add margin for spacing */
      }  
      .aboutlower-left img {
        display: none;
         /* Adjusted height for smaller screens */
         /* Adjusted width for smaller screens */
      }  
      .aboutlower-right {
        position: relative; /* Change to relative positioning for smaller screens */
        width: auto; /* Allow width to adjust based on content */
        left: 0; /* Reset left position */
        top: 150px; /* Reset top position */
        padding: 0 1rem; /* Add padding for better spacing */
        height: 700px;
      }
        .aboutlower-right-upper {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    padding-top: 1rem;
  }

  .aboutblue-line {
    position: static;
    margin-bottom: 1rem;
  }
  .aboutheading {
    font-size: 32px; /* Adjust font size for smaller screens */
    line-height: 40px; /* Adjust line height for smaller screens */
    text-align: center; /* Center align text on smaller screens */
  }

  .aboutlower-right-down {
    font-size: 14px; /* Adjust font size for smaller screens */
    line-height: 18px; /* Adjust line height for smaller screens */
    text-align: center; /* Center align text on smaller screens */
  }

  .aboutbutton {
    width: 150px; /* Adjust button width for smaller screens */
    margin-top: 30px; /* Adjust top margin for smaller screens */
    margin-bottom: 30px; /* Adjust bottom margin for smaller screens */
  }
}