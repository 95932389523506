.main-div{
    width: 100%;
    background: hsla(177, 87%, 79%, 1);

background: linear-gradient(90deg, hsla(177, 87%, 79%, 1) 0%, hsla(235, 89%, 70%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(177, 87%, 79%, 1) 0%, hsla(235, 89%, 70%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(177, 87%, 79%, 1) 0%, hsla(235, 89%, 70%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#9BF8F4", endColorstr="#6F7BF7", GradientType=1 );
    height:fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 25px;
    border: 1px solid white;


}


.inner-div{
    
    margin: 30px 40px;
    padding: 15px;
   
    border-radius: 15px;;
    background-color: #FFF8E7;
    color: #004e92;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Inknut Antiqua", serif;
    width: 35%;
    height: 150px;
    align-items: center;
    

}
.heading-div{
    width: 90%;
    font-size: larger;
    text-align: center;

}
.inner-div h4{
    color:black;
    font-weight: bold;
    text-align: right;
    
}
.inner-div p{
    color:black;

}
.Credentials img{
    width:50px;
    height: 50px;
    border-radius: 50px;
}
.Credentials p{
    line-height: 5px;
}
.name{
    color: black;
    font-weight: bold;
}


@media screen and (max-width: 800px) {
    .main-div{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .inner-div {
        margin: 10px; 
        padding: 10px; 
        height: fit-content;
    }

    .inner-div h4 {
        font-size: 1.2rem; 
    }

    .inner-div p {
        font-size: 0.9rem; 
    }

    .Credentials img {
        width: 40px; 
        height: 40px; 
    }
}

@media screen and (max-width: 480px) {
    .inner-div {
        width: 95%;
        max-width: none; 
        height: fit-content;
    }

    .inner-div h4 {
        font-size: 1rem; 
    }

    .inner-div p {
        font-size: 0.8rem;
    }

    .Credentials img {
        width: 30px; 
        height: 30px; 
    }
}