.brand-names {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; 
    text-align: center; 
}
.btn-fame{
    background-color:  #0081A1;
    color:#ffffff;
    height: 50px;
    width: 300px;
    cursor: pointer;
    font-family:  serif;
    font-weight: bold;
    font-size: large;
    font-style: normal;
    margin-top: 20px;
    transition: 0.2s linear;
}

ul {
    display: flex;
    flex-wrap: wrap; 
    margin: 20px; 
    justify-content: space-around; 
    padding: 0;
    list-style-type: none; 
}

.brand-heading {
    margin: 0;
    font-family: "Inknut Antiqua", serif;
    font-style: normal;
    color: #fff;
    font-size: 3rem; 
    text-align: center; 
}

.brand-heading span {
    color: #55b7ce;
}

.brandHeadings {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
}

.brandImg {
    width: 150px; 
    height: auto; 
    margin: 10px;
}

.brand-logo {
    margin: 20px; 
}