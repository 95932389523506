
body{
   
    overflow-x: hidden;
}


h1{
    margin:0;
    
}
.home-content h1{
    color: #fff;
    font-size: 30px;
    text-align: center;
}
.main-section{
    display: flex;
    flex-direction: column;
    background-color:#000000;

}
.home-container{
    display:flex;
    justify-content:space-evenly;
    background-image: url('../Images/Desktop14.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    margin-top: 150px;
    font-family: "Inknut Antiqua", serif;
    font-weight: 700;
    height: 350px;
    font-style: normal;
    width: 100%;
  
   
    

}
.home-container img{
    width:371px;
    height: 318px;
    
}
.home-content{
    display: flex;
    flex-direction: column;
    text-align: center;
    color:#fff;
    align-items: center;
    margin: 0;
  
    width: 800px;
}

.headin h1{
    
    
    font-size: 85px;
}
.main-para{
    color: grey;
    font-family: "Inknut Antiqua", serif;
    
    

}
.home-card{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: -50px 40px;
    

}

.heading-span{
    color: #0081A1;
    
}
.btn-home{
    background-color:  #0081A1;
    color:#ffffff;
    height: 50px;
    width: 150px;
    cursor: pointer;
    font-family:  serif;
    font-weight: bold;
    font-size: large;
    font-style: normal;
    margin-top: 20px;
    transition: 0.2s linear;

}
.btn-home:hover{
    color: #000000;
}

.aboutHome{
    display: flex;
    margin: 75px;
    color: #fff;
}
.aboutHome{
    background-color: #333;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 20px;
}

.heading{
    position: relative;
    padding-top: 20px; 
    margin-top: 20px; 
    font-family: "Inknut Antiqua", serif;
    font-size: 40px;
    font-style: normal;

}
.heading::before{
    content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 160px;
            height: 5px; /* Border height */
            background-color: #55B7CE; /* Border color */
}
.para{
    font-family: serif;
    color: grey;
}
.contbutton{
    position: fixed;
    background-color: green;
    width: 60px;
    height: 50px;
    z-index: 1;
    margin-top: 100px;
}
.contbutton img{
    width: 60px;
    height: 50px;
}
.blog{
    margin:auto;
    display:flex;
    align-items:center;
    color:white;
}
.blog-image{
    width: 500px;
    height:350px;
    margin-top: -20px;
}
.blogContent{
    margin-top: 25px;
}
.blogContent h2{
    text-align: left;
}
/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
    .home-container{
        background-size: cover;
    }
    .headin h1 {
        font-size: 8vw; 
    }
    .blog-image{
        display: none;
    }
    .blogContent{
        text-align: center;
    }
    .blogContent h2{
        text-align: center;
    }

    .btn-home {
        width: 30vw;
        height: 8vw;
        font-size: 3vw; 
    }

    .contbutton {
        width: 15vw; 
        height: 10vw; 
        margin-top: 20vw; 
    }
    .home-card {
        display: flex;
        flex-direction: column;
        align-items: center; 
        margin: 20px; 
    }
    .about-image{
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 480px) {
    .headin h1 {
        font-size: 10vw;
    }
    .blog-image{
        display: none;
    }
    .blogContent{
        text-align: center;
    }
    .blogContent h2{
        text-align: center;
    }

    .btn-home {
        width: 45vw; 
        height: 10vw; 
        font-size: 4vw; 
    }

    .contbutton {
        width: 20vw; 
        height: 12vw; 
        margin-top: 25vw; 
    }

    .home-container img {
        width: 90%; 
    }
   
}

@media screen and (max-width: 350px) {
    .headin h1 {
        font-size: 12vw; 
    }
    .blog-image{
        display: none;
    }
    .blogContent{
        text-align: center;
    }
    .blogContent h2{
        text-align: center;
    }
    .btn-home {
        width: 60vw; 
        height: 12vw;
        font-size: 5vw; 
    }

    .contbutton {
        width: 25vw; 
        height: 15vw; 
        margin-top: 30vw; 
    }

    .home-container img {
        width: 100%; 
    }
   
}