.main-container{
    background-color: #000000;
    background-image: url('../Images//desktop.png');
    background-size: contain;
    background-repeat: no-repeat;
    color: #ffffff;
    min-height: 100vh;
    overflow-x:hidden ;
    font-family: Inknut Antiqua;
    font-weight: 700;
    
}
.upper-sec{
    
   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 2rem;
    margin-top: 160px;
}
.upper-sec h1{
    font-size: 3.5rem;
}
.upper-sec p{
    color: #808080;
   
    
}
.upper-sec a{
    text-decoration: none;
}
.upper-sec p:hover{
    color: #FFFFFF;
}
.lower-sec{
  display: flex;
  flex-direction: column;
 
}
.image-item{
    display: flex;
    border-radius: 10px;
    margin: 3rem;
    padding: 25px;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid white;
    
}
.image-item img{
    height: 35vh;
    width: 30vw;
}
.image-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 3rem;
}
.image-details h3{
margin: 0.3rem;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
color: #808080;


}
.image-details h4{
margin: 0.2rem;
font-family: 'Inknut Antiqua';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;
color: #FFFFFF;
}
.image-details p{
font-family: 'Inknut Antiqua';
font-style: normal;
font-weight: 700;
font-size: 0.7rem;

text-align: center;

color: #808080;


}
@media (max-width:480px) {
    .upper-sec{
        padding-top: 1.5rem;
        line-height: 1rem;
    }
    .upper-sec h1{
        font-size: 2rem;
    }
    .upper-sec p{
        font-size: .8rem;
    }
    .image-item{
        margin: 0;
        align-items: center;

    }
    .image-item img{
        height: 25vh;
        width: 50vw;
        margin: 2rem;
    }
    .image-details{
    padding: 0;
    margin: 0;
       
    }
    .image-item:nth-child(odd) .image-details {
        margin-left: 0;
        margin-right: 1rem; 
    }

    
    .image-item:nth-child(even) .image-details {
        margin-left: 1rem;
        margin-right: 0; 
    }
    .image-details h3{
        font-size: 1rem;
    }
    .image-details h4{
        font-size: 0.8rem;
    }
    .image-details p{
        font-size: 0.5rem;
    }
}