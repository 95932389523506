
.containernew{
    background: #000428;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #004e92, #000428);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: white;
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
   
}
.headers {
    text-align: center;
    margin-bottom: 30px;
}
.button{
    background-color:  #0081A1;
    color: #ffffff;
    height: 49px;
    width: 80%; /* Adjusted width to fit container */
    max-width:  329px; /* Added max-width to prevent overflow */
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 50px;
    transition: color 0.3s ease;
    margin-bottom: 50px;
}
.button a{
    text-decoration: none;
    transition: color 0.3s ease;
   color: #ffffff;
}
.button a:hover {

 color:black
}
@media (max-width: 768px) {
    .headers h1 {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .button {
        width: 100%; /* Full width on smaller screens */
        max-width: 400px; /* Remove max-width on smaller screens */
    }
}

@media (max-width: 480px) {
    .headers h1 {
        font-size: 20px; /* Further reduce font size for smaller screens */
    }

    .button {
        font-size: 16px; /* Further reduce font size for smaller screens */
        width: 100%;
        max-width: 200px;
    }
}