.footer-div{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000428;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #004e92, #000428);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: white;
    text-align: center;
    padding: 20px;
    border-bottom: 4px solid white;
}
.nav-Links{
  display: flex;
  color: #fff;

}
.nav-Links p{
  text-decoration: none;
  color: white;
}
.footer-heading{
    color: #fff;
    margin-bottom: 20px;
    font-family: "Inknut Antiqua", serif;

}
.nav-Links a{
  color: white;
  font-weight: bold;
}
.footer-icons a {
    color: #fff;
    margin: 0 15px;
    text-decoration: none;
  }
  
  .footer-icons a:hover {
    color: #ccc;
  }
  .footer-icon{
    width: 400px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .footer-icon .icon {
    font-size: 25px;
    color: white;
    transition: color 0.3s;
  }
  .footer-icon .icon:hover {
    color: #cccccc;
  }
  .watermark p{
    color:white; 
    margin-top: 5px;
  }
  .copy{
    font-size: 9px;
    color: grey;
  }
  @media (max-width: 480px) {
    .footer-div {
        padding: 10px;
    }
    .footer-icon {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .footer-icons a {
        margin: 5px 0;
    }
}


@media (min-width: 481px) and (max-width: 768px) {
    .footer-div {
        padding: 15px;
    }
    .footer-icon {
        width: 300px;
        flex-direction: column;
        align-items: center;
    }
    .footer-icons a {
        margin: 10px 0;
    }
}
