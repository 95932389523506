
.main{
    min-height: 100vh;
   
}
.container{
    background-color: black;
    background-image: url('../Images/Desktop14.png');
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    height: 100%;
    width: 100%;
    padding-bottom: 10vh;
    
   
}
.upper{
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    padding-top: 2rem; /* Adjust padding as needed */
   
}
.upper h1{
    font-family: Inknut Antiqua;
    font-weight: 700;
    color:#ffffff;
font-size: 74px;
line-height: 3rem;
margin-top: 150px;
}
.upper span{
    color:  #808080;
    font-family: Inknut Antiqua;
    font-weight: 700;
    font-size: 16px;
}

.upper span a{
    color: #808080;
   text-decoration: none;
}
.upper span a:hover {
    color: #ffffff;
  }

.lower{
    display: flex;
    margin-top: 100px;
    margin-bottom: 0;
    /* flex-direction: column; Stack items vertically on small screens */
    justify-content: center;
}
.lower-left{
    display: flex;
    justify-content: space-between;
    
}
.lower-left img{
    height: 260px;
    width: 406px;
    box-shadow: -51px 36px 4.9px 0px #0081A1;
   margin-bottom: 5px;
}
.lower-right{
    display: flex;
    flex-direction: column;
    margin-left: 0; /* Reset margin for smaller screens */
    text-align: center; /* Center align text */
    margin-left: 100px;
}
.lower-right-upper{
    display: flex;
    align-items: center;

}
.heading::before{
    display: none;
}
.heading{
    margin-left: 40px;
    margin-top: 2px;

    
}
h2{
    font-family: Inknut Antiqua;
    font-weight: 700;
    font-size: 1.5rem; /* Adjust font size for responsiveness */
    margin: 0;
    font-size: 40px;
    text-align: left;
}
.lower-right-down ul{
    display: flex;
    flex-direction: column;
}
.lower-right-down ul{
    list-style: none;
}
.lists{
    display: flex;
    margin-left: -25px;
    padding: 10px;
    font-family: Inknut Antiqua;
    font-weight: 700;
}
.check {
    width: 25px;
    height: 25px;
 
    margin-right: 1rem;
  }


.blue-line {
    border-left: 6px solid rgb(37, 141, 193);
    height: 80px;
  }
  .contbutton{
    position: fixed;
    background-color: green;
    width: 60px;
    height: 50px;
    z-index: 1;
}
.contbutton img{
    width: 60px;
    height: 50px;
}

  @media (max-width: 768px) {
    .upper {
        padding-top: 1rem;
    }

    .upper h1 {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .upper span {
        font-size: 0.875rem;
    }

    .lower {
        margin-top: 2rem;
    }

    .lower-left img {
        width: 80%; /* Ensure image scales properly */
    }

    .lower-right {
        text-align: center;
        margin-left: 0;
    }

    .heading h2 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .lists {
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .main{
        width: 100vw;
    }
    .upper {
        padding-top: 1rem;
    }

    .upper h1 {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .upper span {
        font-size: 0.75rem;
    }

    .lower {
        margin-top: 1rem;
         flex-direction: column; /*Stack items vertically on small screens*/
    }

    .lower-left img {
        width: 100%; /* Ensure image scales properly */
        box-shadow: none;
    }

    .lower-right {
        text-align: center;
        margin-left: 0;
    }

    .heading h2 {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .lists {
        font-size: 0.75rem;
    }
}