.cardh {
    
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    background-color: aliceblue;
    margin: 16px;
    color: white; 
    text-align: center; 
    width: 350px;
  
  }
  .cardh:hover {
    transform: scale(1.05);
  }
  
  .cardh-icon {
    font-size: 4em;
    margin-top: 30px;
    color:blue;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    
  }
  
  .cardh-content {
    padding: 16px;
    font-family: "Inknut Antiqua", serif;
    font-weight: bold;
    font-style: normal;
  }
  
  .cardh-title {
    font-size: 1.5em;
    margin: 0;
    text-align: center;
    color: black;
  }
  
  .cardh-text {
    font-size: 1em;
    margin-top: 8px;
    color: grey;}
  