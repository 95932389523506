.mainread button{
background-color: black;
color: #FFFFFF;
font-family: Inknut Antiqua;
height: 2rem;
width: 9rem;
font-weight: 700;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
border: 1px solid #FFFFFF;
border-radius: 3.65rem;
font-size: 1rem;
cursor: pointer;

}
.mainread button:hover{
    color:beige;
}
.mainread a{
    text-decoration: none;
}
@media (max-width:480px) {
    .mainread button{
        font-size: 0.7rem;
        width: 6rem;
    }
}

