/* .containerbottom{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}
.blueline{
    width: 199px;
    height: 5px;
    background-color: #55B7CE;
    position: absolute;
    left: 173px;
    top: 200px;
}
.head{
    position: absolute;
    font-family: Inknut Antiqua;
    color: #ffffff;
    left: 173px;
    top: 210px;
font-size: 64px;
font-weight: 500;
line-height: 72px;
text-align: left;

}

.list ul{
list-style: none;
color: #ffffff;
position: absolute;
left: 853px;
top: 210px;
font-family: Inknut Antiqua;
font-size: 32px;
font-weight: 500;
line-height: 72px;
text-align: left;
} */
.containerbottom {
    background-color: black;
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items:baseline;
    min-height: 100vh; 
    padding: 20px; 
    box-sizing: border-box;
    position: relative;
}

.blueline {
    width: 199px;
    height: 5px;
    background-color: #55B7CE;
   
}
.content {
    display: flex;
    flex-direction: row; /* Head and list side by side */
    justify-content: center;
    align-items: flex-start; /* Align to the top */
    width: 100%;
}
.head {
    font-family: 'Inknut Antiqua', serif;
    color: #ffffff;
    font-size: 2.5rem; /* Adjusted font size for better responsiveness */
    font-weight: 500;
    line-height: 1.2rem;
    text-align: center; /* Centered text */
    margin: 20px 0; /* Added margin for spacing */
}
.head span{
    line-height: 2.5rem;
    color: #ffffff;
}
.left-con{
    margin-left: 10rem;
}
.right-con{
   padding-right: 10rem;
}

.list-bottom{
   
    align-items: flex-start;
}
.list-bottom ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    font-family: 'Inknut Antiqua', serif;
    font-size: 1.5rem; /* Adjusted font size for better responsiveness */
    font-weight: 500;
    line-height: 1.5;
    text-align: center; /* Centered text */
}

.list-bottom ul li {
    margin: 20px 0; /* Added margin for spacing */
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-bottom ul li img {
    margin-right: 10px; /* Added margin for spacing */
}

@media (min-width: 820px) and (max-width: 1024px) {
    .containerbottom {
        min-height: 50vh;
       
    }
}
@media (max-width: 768px) {
    .head {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .list-bottom ul {
        font-size: 1.25rem; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .containerbottom{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .blueline {
        width: 150px; /* Adjust width for smaller screens */
        position:static;

    }
    .left-con{
    margin: 0;
      
        
    }
    .right-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        
    }
    .content {
        display: flex;
        flex-direction: column; /* Head and list side by side */
        justify-content: center;
        align-items: center; /* Align to the top */
        width: 100%;
    }
    .head {
        font-size: 1.75rem; /* Further adjust font size for smaller screens */
    }

    .list-bottom ul {
        font-size: 1rem; /* Further adjust font size for smaller screens */
    }

    .list-bottom ul li {
        flex-direction: column; /* Stack items vertically */
    }

    .list-bottom ul li img {
        margin-bottom: 10px; /* Adjust margin for vertical stacking */
    }
}